import { useState, useEffect, useRef, type KeyboardEvent } from 'react';

/**
 * Props for the Editable component
 *
 * @template T - The type of the HTML element that will be edited.
 */
type EditableProps<T extends HTMLElement> = {
  value: string | number; // The current value to be displayed or edited.
  type?: 'input' | 'textarea' | 'div'; // The type of editable element (e.g., input, textarea, or div).
  placeholder?: string; // Placeholder text when no value is present.
  className?: string; // CSS class for styling the non-editing display.
  customDisplayComponent?: React.ReactNode; // Custom component to display when not editing.
  disabled?: boolean; // Whether the editable component is disabled.
  onChange?: (value: string | number) => void; // Callback when the value is updated.
  onCancel?: () => void; // Callback when editing is canceled.
  onEditingChange?: (isEditing: boolean) => void; // Notify parent about editing state
  changeOnBlur?: boolean; // Whether to save changes on blur.
  children: (
    props: React.HTMLAttributes<T>, // Props for the editable element.
    ref: React.RefObject<T>, // Ref to the editable element.
    actions: { save: () => void; cancel: () => void } // Actions to manually save or cancel editing.
  ) => React.ReactNode; // Render prop for customizing the editing UI.
};

/**
 * Editable Component
 *
 * A reusable component that allows inline editing of content with customizable controls.
 */
export const Editable = <T extends HTMLElement>({
  value,
  type = 'input',
  placeholder,
  className,
  customDisplayComponent,
  disabled = false,
  onChange,
  onCancel,
  onEditingChange,
  changeOnBlur = false,
  children,
  ...props
}: EditableProps<T>) => {
  const [isEditing, setEditing] = useState(false); // Tracks whether the component is in editing mode.
  const [internalValue, setInternalValue] = useState<string | number>(value); // Holds the temporary editing value.
  const childRef = useRef<T>(null); // Ref to the editable element.

  // Focus on the editable element when entering editing mode.
  useEffect(() => {
    if (childRef.current && isEditing) {
      childRef.current.focus();
    }
  }, [isEditing]);

  // Notify the parent component about the editing state.
  useEffect(() => {
    onEditingChange?.(isEditing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // Sync internalValue with the value prop when it changes (if not editing).
  useEffect(() => {
    if (!isEditing) {
      setInternalValue(value);
    }
  }, [value, isEditing]);

  // Trigger editing mode unless the component is disabled.
  const handleTriggerEdit = () => {
    if (disabled) return;
    setEditing(true);
  };

  // Save the updated value and exit editing mode.
  const finishEditing = () => {
    setEditing(false);
    if (onChange && internalValue !== '' && internalValue !== value) {
      onChange(internalValue);
    }
  };

  // Cancel editing and reset the value to the original.
  const cancelEditing = () => {
    setEditing(false);
    setInternalValue(value); // Reset internalValue to the original value.
    if (onCancel) {
      onCancel();
    }
  };

  // Handle keyboard events for saving or canceling.
  const handleKeyDown = (event: KeyboardEvent<T>) => {
    const { key } = event;
    const keysToFinish = ['Tab', 'Enter']; // Keys to trigger save.

    if (keysToFinish.includes(key)) {
      finishEditing(); // Save on Tab or Enter.
    } else if (key === 'Escape') {
      cancelEditing(); // Cancel on Escape.
    }
  };

  // Handle blur events to save or cancel based on the changeOnBlur prop.
  const handleBlur = () => {
    if (changeOnBlur) {
      finishEditing();
    } else {
      cancelEditing();
    }
  };

  return (
    <section {...props}>
      {isEditing ? (
        // Render the children with provided props, ref, and save/cancel actions.
        children(
          {
            onKeyDown: handleKeyDown,
            onBlur: handleBlur,
            ...(type === 'input' || type === 'textarea' ? { value: internalValue } : {}), // Add value for input/textarea.
            onChange: (e: React.FormEvent<T>) =>
              setInternalValue((e.target as HTMLInputElement | HTMLTextAreaElement).value), // Update internal value on change.
          },
          childRef,
          { save: finishEditing, cancel: cancelEditing }
        )
      ) : (
        <div onClick={handleTriggerEdit}>
          {customDisplayComponent ? (
            customDisplayComponent // Use a custom display component if provided.
          ) : (
            <div className={className}>{internalValue || placeholder}</div> // Default display when not editing.
          )}
        </div>
      )}
    </section>
  );
};
