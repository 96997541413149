import { observer } from 'mobx-react-lite';
import { models } from 'data-plumber';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cn, convertMinToHours } from '@/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  CardStackPlusIcon,
  ClockIcon,
  DotsVerticalIcon,
  InputIcon,
  MoveIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@atoms';
import { ReactSVG } from 'react-svg';
import TrainIcon from '@mestaicons/train.svg';
import TaskIcon from '@mestaicons/task.svg';

type TaktTrainTemplateCardProps = {
  taktTrainTemplate: models.ITaktTrainTemplate;
  onChangeName: () => void;
  onClone: () => void;
  onDelete: () => void;
};

export const TaktTrainTemplateCard: React.FC<TaktTrainTemplateCardProps> = observer(
  ({ taktTrainTemplate, onChangeName, onClone, onDelete }) => {
    const { t } = useTranslation();

    const { transform, transition, setNodeRef, attributes, listeners, isDragging } = useSortable({
      id: taktTrainTemplate.id.toString(),
      data: {
        taktTrainTemplate,
      },
    });

    const style: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition: transition,
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
      navigate(taktTrainTemplate.id.toString());
    };

    return (
      <div
        ref={setNodeRef}
        style={style}
        className={cn(
          'flex-1 border-2 border-gray-300 p-2 bg-background',
          'flex flex-col py-1 cursor-pointer w-full',
          'hover:shadow-md transform hover:scale-[1.01] transition-all',
          isDragging && 'z-10'
        )}
        onClick={handleNavigate}
      >
        <div className='flex flex-row justify-between items-end'>
          <div className='font-bold text-lg'>{taktTrainTemplate.name}</div>
          {taktTrainTemplate.spaceIds.length > 0 && (
            <div className='flex-grow text-sm pl-20 pb-0.5'>
              <Trans
                i18nKey='taktTemplate:usedInSpaces'
                values={{ count: taktTrainTemplate.spaceIds.length }}
                components={{
                  counter: <span className='font-bold text-base' />,
                }}
              />
            </div>
          )}
          <div className='flex flex-row items-center'>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  {...attributes}
                  {...listeners}
                  className='p-1 cursor-grab hover:bg-slate-400 dark:hover:bg-slate-900 transition-all'
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <MoveIcon className='w-5 h-5' />
                </div>
              </TooltipTrigger>
              <TooltipContent align='center' className='text-center'>
                {t('taktTemplate:train.holdAndDrag')}
              </TooltipContent>
            </Tooltip>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className='p-1 cursor-pointer hover:bg-slate-400 dark:hover:bg-slate-900 transition-all'>
                  <DotsVerticalIcon className='w-5 h-5' />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onChangeName();
                  }}
                >
                  <InputIcon className='w-5 mr-2' /> {t('rename')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onClone();
                  }}
                >
                  <CardStackPlusIcon className='w-5 mr-2' /> {t('clone')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className='text-destructive'
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                >
                  <TrashIcon className='w-5 mr-2' /> {t('delete')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className='flex flex-row py-2'>
          <div className='flex flex-row items-end min-w-0'>
            <div className='min-w-fit mr-2'>
              <div className='flex flex-row items-center'>
                <ReactSVG src={TrainIcon} beforeInjection={(svg) => svg.classList.add('w-4')} />
                <div className='font-bold text-base ml-2'>
                  {taktTrainTemplate.taktWagonTemplatesArray.length}
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <ClockIcon className='w-4 h-4' />
                <div className='font-bold text-base ml-2'>
                  {convertMinToHours(taktTrainTemplate.overallEstimatedDuration)}
                </div>
              </div>
            </div>
            <div className='flex flex-row overflow-x-auto no-scrollbar'>
              {taktTrainTemplate.taktWagonTemplatesArray.map((wagon) => (
                <Tooltip key={wagon.id}>
                  <TooltipTrigger asChild>
                    <div className='flex flex-row items-center'>
                      <div
                        className={cn(
                          'font-semibold mx-2 px-4',
                          'border-2 border-gray-400 rounded-sm',
                          wagon.buffer && 'bg-lime-200'
                        )}
                      >
                        {wagon.buffer
                          ? t('taktTemplate:wagon.bufferInitial')
                          : wagon.taskTemplatesArray.length}
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent align='center' className='text-center'>
                    <div className='font-bold text-sm'>{wagon.name}</div>
                    {wagon.buffer ? (
                      `(${t('taktTemplate:wagon.buffer')})`
                    ) : (
                      <div className='flex flex-col items-center'>
                        <div className='flex flex-row items-center my-1'>
                          <ReactSVG
                            src={TaskIcon}
                            beforeInjection={(svg) => svg.classList.add('w-3')}
                            className='mr-1.5'
                          />
                          <div className='font-semibold'>{wagon.taskTemplatesArray.length}</div>
                        </div>
                        <div className='flex flex-row items-center'>
                          <ClockIcon className='w-4 h-4 mr-1.5' />
                          <div className='font-semibold'>
                            {convertMinToHours(wagon.totalEstimatedDuration)}
                          </div>
                        </div>
                      </div>
                    )}
                  </TooltipContent>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
