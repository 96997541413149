import { useState } from 'react';
import { useStores } from 'data-plumber';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@atoms';
import TeamIcon from '@/assets/icons/team.svg';
import { cn } from '@/utils';
import { TriangleDownIcon } from '@radix-ui/react-icons';

type TeamSelectorProps = {
  selectedTeamId: number | null;
  onChange?: (value: string | number | null) => void;
};

export const TeamSelector: React.FC<TeamSelectorProps> = ({ selectedTeamId, onChange }) => {
  const { t } = useTranslation();

  const [openTeamSelector, setOpenTeamSelector] = useState(false);

  const {
    projectsStore: { selectedProject },
  } = useStores();

  if (!selectedProject) return null;

  const handleChangeTeam = (teamId: string | null) => {
    setOpenTeamSelector(false);
    if (onChange) onChange(teamId);
  };

  return (
    <Popover open={openTeamSelector} onOpenChange={setOpenTeamSelector}>
      <PopoverTrigger asChild>
        <div className={cn('flex flex-row', 'items-center justify-between cursor-pointer')}>
          <div className='mr-2'>
            <ReactSVG src={TeamIcon} className='mr-1 fill-current' />
          </div>
          <div className='flex-1 text-xs font-medium truncate'>
            {selectedTeamId
              ? selectedProject.getTeamNameById(selectedTeamId)
              : t('taktTemplate:taskCard.noTeam')}
          </div>
          <div className='ml-1'>
            <TriangleDownIcon className='w-4 h-4' />
          </div>
        </div>
      </PopoverTrigger>

      <PopoverContent className='w-[200px] p-0'>
        <Command
          filter={(_value, search, keywords) => {
            if (keywords?.join(' ').toLocaleLowerCase().includes(search.toLocaleLowerCase()))
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={t('taktTemplate:taskCard.searchTeams')} />
          <CommandList>
            <CommandEmpty>
              <span className='text-destructive'>{t('taktTemplate:taskCard.teamNotFound')}</span>
            </CommandEmpty>
            <CommandGroup className='p-0'>
              <div className='flex flex-row'>
                <div className='w-2 bg-defaultTeamColor' />
                <CommandItem
                  onSelect={() => handleChangeTeam(null)}
                  className={cn(
                    'flex-1 hover:!bg-slate-300 focus:!bg-slate-300',
                    'cursor-pointer break-words hyphens-auto',
                    selectedTeamId === null &&
                      '!bg-foreground hover:!bg-foreground !text-background cursor-default'
                  )}
                >
                  {t('taktTemplate:taskCard.noTeam')}
                </CommandItem>
              </div>
              {selectedProject.teamArray.map((team) => (
                <div key={team.id} className='flex flex-row'>
                  <div
                    className='w-2 bg-defaultTeamColor'
                    style={{ backgroundColor: team.color ? `#${team.color}` : undefined }}
                  />
                  <CommandItem
                    key={team.id}
                    value={team.id.toString()}
                    keywords={team.name.split(' ')}
                    onSelect={handleChangeTeam}
                    className={cn(
                      'flex-1 hover:!bg-slate-300 focus:!bg-slate-300',
                      'cursor-pointer break-words hyphens-auto',
                      team.id === selectedTeamId &&
                        '!bg-foreground hover:!bg-foreground !text-background cursor-default'
                    )}
                  >
                    {team.name}
                  </CommandItem>
                </div>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
