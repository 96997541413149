import i18n, { t } from 'i18next';
import { fallbackLocale } from '@/i18n';

/**
 * Output formatted localized date/time
 * @param datetime An object of type `Date`
 * @param options An object of type `Intl.DateTimeFormatOptions`.
 * For `options`, please consult:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @return Translated string of formatted date/time
 */
export const formatLocaleDateTime = (datetime: Date | null, options?: Intl.DateTimeFormatOptions) =>
  new Intl.DateTimeFormat(i18n.resolvedLanguage || fallbackLocale, options).format(
    datetime || new Date()
  );

/**
 * Output formatted localized date/time range
 * @param startDateTime An object of type `Date`
 * @param endDateTime An object of type `Date`
 * @param options An object of type `Intl.DateTimeFormatOptions`.
 * For `options`, please consult:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @return Translated string of formatted date/time range
 */
export const formatLocaleDateTimeRange = (
  startDateTime: Date | null,
  endDateTime: Date | null,
  options?: Intl.DateTimeFormatOptions
) => {
  // Both start AND end date/time
  if (startDateTime && endDateTime)
    return `${formatLocaleDateTime(startDateTime, options)} – ${formatLocaleDateTime(
      endDateTime,
      options
    )}`;
  // Only start OR end date/time
  return formatLocaleDateTime(startDateTime || endDateTime, options);
};

/**
 * Output long localized date
 * (e.g. "December 19, 2023" or "19. joulukuuta 2023")
 * @param date An object of type `Date`
 * @param options An object of type `Intl.DateTimeFormatOptions`.
 * @return Translated string of long date
 */
export const formatLocaleLongDate = (date: Date | null) =>
  formatLocaleDateTime(date, { dateStyle: 'long' });

/**
 * Output long localized date range
 * (e.g. "September 4, 2023 – December 19, 2023"
 * or "4. syyskuuta 2023 – 19. joulukuuta 2023")
 * @param startDate An object of type `Date`
 * @param endDate An object of type `Date`
 * @return Translated string of long date range
 */
export const formatLocaleLongDateRange = (startDate: Date | null, endDate: Date | null) =>
  formatLocaleDateTimeRange(startDate, endDate, { dateStyle: 'long' });

/**
 * Convert minutes to hours and minutes
 * (e.g. "1 h 30 min" or "1 t 30 min")
 * @param minutes A number of minutes
 * @return Translated string of hours and minutes
 * @param shortForm A boolean to return short form (e.g. "1:30")
 */
export const convertMinToHours = (minutes: number, shortForm?: boolean) => {
  if (shortForm) return (minutes / 60).toLocaleString(i18n.resolvedLanguage || fallbackLocale);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hourText = `${hours} ${t('datetime.hourShort')}`;
  const minuteText = `${remainingMinutes.toFixed(0)} ${t('datetime.minuteShort')}`;
  return hours < 1 ? minuteText : remainingMinutes < 1 ? hourText : `${hourText} ${minuteText}`;
};

/**
 * Output countdown string
 * @param days A number of days
 * @param hours A number of hours
 * @param minutes A number of minutes
 * @param seconds A number of seconds
 * @return Translated string of countdown
 */
export const outputCountdownString = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => {
  let timeParts = '';

  if (days > 0) timeParts += `${days} ${t('countdown.days', { count: days })} `;
  if (hours > 0) timeParts += `${hours} ${t('countdown.hours', { count: hours })} `;
  if (minutes > 0) timeParts += `${minutes} ${t('countdown.minutes', { count: minutes })} `;
  if (days=== 0 && hours === 0 && minutes === 0 && seconds > 0)
    timeParts += `${seconds} ${t('countdown.seconds', { count: seconds })}`;

  return timeParts ? timeParts.trim() : '---';
};

/**
 * Output takt locale date time parts
 * @param datetime
 * @returns Translated string of takt locale date time parts
 */
export const getTaktLocaleDateTimeParts = (datetime: Date) => {
  return {
    dayOfWeek: new Intl.DateTimeFormat(i18n.resolvedLanguage || fallbackLocale, {
      weekday: 'short',
    }).format(datetime),
    date: new Intl.DateTimeFormat(i18n.resolvedLanguage || fallbackLocale, {
      month: '2-digit',
      day: '2-digit',
    }).format(datetime),
    time: new Intl.DateTimeFormat(i18n.resolvedLanguage || fallbackLocale, {
      hour: '2-digit',
      minute: '2-digit',
    }).format(datetime),
  };
}
  
