import { useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { models } from 'data-plumber';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useApiCallback, useTaktTrainTemplateDnD } from '@/hooks';
import { TASK_TEMPLATE_DRAG_TYPE } from '../constants';
import { TaskCard } from '../Components';

type TaskTemplateProps = {
  taskTemplate: models.ITaskTemplate;
  taktWagonTemplate: models.ITaktWagonTemplate;
  pendingCard?: boolean;
  onDelete?: () => void;
  collapsed: boolean;
};

type TaskTemplateUpdateForm = Pick<models.ITaskTemplate, 'name' | 'estimatedDuration' | 'teamId'>;

export const TaskTemplate: React.FC<TaskTemplateProps> = observer(
  ({ taskTemplate, taktWagonTemplate, pendingCard = false, onDelete, collapsed }) => {
    const [editMode, setEditMode] = useState(false);

    const updateTaskTemplateApi = useApiCallback(taktWagonTemplate.updateTaskTemplate);
    const { draggingTaskCard } = useTaktTrainTemplateDnD();

    const pending = useMemo(() => {
      return (
        pendingCard ||
        updateTaskTemplateApi.status === 'loading' ||
        (draggingTaskCard?.id === taskTemplate.id && draggingTaskCard?.pending)
      );
    }, [
      pendingCard,
      updateTaskTemplateApi.status,
      draggingTaskCard?.id,
      draggingTaskCard?.pending,
      taskTemplate.id,
    ]);

    const draggable = useMemo(() => !(editMode || pending), [editMode, pending]);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: 'task-' + taskTemplate.id.toString(),
      data: {
        type: TASK_TEMPLATE_DRAG_TYPE,
        taskTemplate,
        teamColor: taskTemplate.teamColor || null,
        taktWagonTemplate,
      },
      disabled: !draggable,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
      setValue,
    } = useForm<TaskTemplateUpdateForm>({
      defaultValues: {
        name: taskTemplate.name,
        estimatedDuration: taskTemplate.estimatedDuration,
        teamId: taskTemplate.teamId,
      },
    });

    const onSubmit: SubmitHandler<TaskTemplateUpdateForm> = useCallback(
      (updatedTaskTemplate) => {
        updateTaskTemplateApi.call(taskTemplate.id, updatedTaskTemplate);
      },
      [taskTemplate, updateTaskTemplateApi]
    );

    // Watch for changes in teamId and submit the form
    useEffect(() => {
      const subscription = watch((_value, { type }) => {
        if (type === 'change') {
          handleSubmit(onSubmit)();
        }
      });
      return () => subscription.unsubscribe();
    }, [handleSubmit, onSubmit, watch]);

    // Set the estimatedDuration value to the form
    useEffect(() => {
      setValue('estimatedDuration', taskTemplate.estimatedDuration);
    }, [setValue, taskTemplate.estimatedDuration]);

    const handleRenameSubmit = () => {
      handleSubmit(onSubmit)();
      setEditMode(false);
    };

    const handleRenameCancel = () => {
      setEditMode(false);
      reset();
    };

    // If updated succesfully, reset the form with new values,
    // otherwise if error occurs, reset the form to the previous values
    useEffect(() => {
      if (updateTaskTemplateApi.status === 'fetched' && updateTaskTemplateApi.data) {
        reset({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore #FIXME
          name: updateTaskTemplateApi.data.name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore #FIXME
          teamId: updateTaskTemplateApi.data.teamId,
        });
      } else if (updateTaskTemplateApi.status === 'error') {
        reset();
      }
    }, [updateTaskTemplateApi.status, updateTaskTemplateApi.data, reset]);

    return (
      <div
        ref={draggable ? setNodeRef : undefined}
        className='mt-1 first:mt-0'
        style={style}
        {...attributes}
        {...listeners}
      >
        <TaskCard
          displayTaskName={watch('name')}
          taskTemplate={taskTemplate}
          pendingCard={pending}
          editMode={editMode}
          collapsed={collapsed}
          isDragging={isDragging}
          isUpdating={updateTaskTemplateApi.status === 'loading'}
          onDelete={onDelete}
          formControl={control}
          formErrors={errors}
          onEditModeChange={setEditMode}
          onRenameSubmit={handleRenameSubmit}
          onRenameCancel={handleRenameCancel}
        />
      </div>
    );
  }
);
