// TODO - needs some tuning - Placeholder for now
const fonts = {
  openSans: {
    normal: 'OpenSans',
    bold: 'OpenSans', // UI Design only specifies Semibold
  },
  openSansCondensed: {
    normal: 'OpenSansCondensedRegular',
    bold: 'OpenSansCondensedSemiBold', // UI Design only specifies Semibold
  },
  sen: {
    normal: 'Sen',
    bold: 'Sen',
    extraBold: 'Sen',
  },
  inconsolata: {
    normal: 'Inconsolata',
  },
  monospace: {
    // Android only font.
    normal: 'monospace',
  },
};

export const typography = {
  /**
   * The fonts are available to use, but prefer using the semantic name.
   */
  fonts,
  /**
   * The primary font. Used in most places.
   */
  primary: fonts.openSans,
  condensed: fonts.openSansCondensed,
  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: fonts.sen,
  /**
   * Lets get fancy with a monospace font!
   */
  code: fonts.inconsolata,
};


export const spacing = {
  micro: 2,
  tiny: 4,
  extraSmall: 8,
  small: 12,
  medium: 16,
  large: 24,
  extraLarge: 32,
  huge: 48,
  massive: 64,
};