import i18n from 'i18next';
import { fallbackLocale } from '@/i18n';

/**
 * Extract number from a string.
 * @param text String which contains both text and numbers
 * @return String which contains ONLY numbers
 */
export const extractNumber = (text: string) => text.replace(/\D/g, '');

/**
 * Calculate the percentage
 * @param part Part number
 * @param total Total number
 * @return Percentage
 */
export const calculatePercentage = (part?: number, total?: number) => {
  if (!part || !total || part < 0 || total < 0) return 0;
  return Math.round((part / total) * 100);
};

/**
 * Convert from byte to size
 * @param bytes Number of bytes
 * @return Text contains bytes with unit (e.g. 1 KB, 2 MB, 3 GB, 4 TB)
 */
export const bytesToSize = (bytes: number) => {
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return i18n.t(`byteUnits.${sizes[i]}`, {
    size: (bytes / 1024 ** i).toLocaleString(i18n.resolvedLanguage || fallbackLocale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }),
  });
};


/**
 * Parses a locale-specific formatted number string and converts it to a float.
 *
 * This function handles numbers with group and decimal separators, such as "1,234.56" or "1.234,56".
 * It determines the last separator in the string to identify the decimal separator and normalizes
 * the number by removing group separators and converting the decimal separator to a dot ('.').
 *
 * @param localeNumber - The locale-specific formatted number string to parse.
 * @returns The parsed number as a float.
 * @throws {Error} If the input string does not match the expected number format.
 */
export const parseLocaleNumber = (localeNumber: string) => {
  // Match numbers with possible group and decimal separators
  const numberPattern = /^-?\d{1,3}(?:[,.]\d{3})*(?:[,.]\d+)?$/;

  if (!numberPattern.test(localeNumber)) {
    throw new Error("Invalid number format");
  }

  // Determine the last separator in the string (most likely the decimal separator)
  const lastSeparatorMatch = localeNumber.match(/[,.](?=\d*$)/);
  const decimalSeparator = lastSeparatorMatch ? lastSeparatorMatch[0] : '.';

  // Replace group separators (assume they are not the decimal separator)
  const groupSeparator = decimalSeparator === '.' ? ',' : '.';
  const normalizedNumber = localeNumber
    .replace(new RegExp(`\\${groupSeparator}`, 'g'), '') // Remove group separators
    .replace(new RegExp(`\\${decimalSeparator}`), '.'); // Replace decimal separator with '.'

  return parseFloat(normalizedNumber);
};
