import * as React from 'react';
import { cn } from '@/utils';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

// NumericalInput component that allows only 0-9 and both "." and ","
export type NumericalInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NumericalInput = React.forwardRef<HTMLInputElement, NumericalInputProps>(
  ({ className, onChange, ...props }, ref) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // Allow only digits, dot (.) or comma (,) as separators
      const sanitizedValue = value.replace(/[^0-9.,]/g, '');

      // Call the onChange handler with the sanitized value
      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: sanitizedValue,
          },
        });
      }
    };

    return <Input ref={ref} className={className} {...props} onChange={handleInputChange} />;
  }
);

NumericalInput.displayName = 'NumericalInput';

export { Input, NumericalInput };
