import { memo } from 'react';
import { models } from 'data-plumber';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { Editable, Input, NumericalInput } from '@atoms';
import { cn, convertMinToHours, parseLocaleNumber } from '@/utils';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@atoms';
import { DotsVerticalIcon, TrashIcon, ClockIcon, Pencil1Icon } from '@radix-ui/react-icons';
import { TeamSelector } from './TeamSelector';
import { fallbackLocale } from '@/i18n';

type TaskTemplateUpdateForm = Pick<models.ITaskTemplate, 'name' | 'estimatedDuration' | 'teamId'>;

export type TaskTemplateCardType = {
  taskTemplate: models.ITaskTemplate;
  displayTaskName?: string;
  pendingCard?: boolean;
  editMode?: boolean;
  collapsed?: boolean;
  isDragging?: boolean;
  isUpdating?: boolean;
  formControl?: Control<TaskTemplateUpdateForm>;
  formErrors?: FieldErrors<TaskTemplateUpdateForm>;
  className?: string;
  onDelete?: () => void;
  onEditModeChange?: (editMode: boolean) => void;
  onRenameSubmit?: () => void;
  onRenameCancel?: () => void;
};

const TaskName: React.FC<{ taskTemplate: models.ITaskTemplate; displayTaskName?: string }> = ({
  taskTemplate,
  displayTaskName,
}) => {
  return (
    <div className='font-bold py-1 break-anywhere'>{displayTaskName || taskTemplate.name}</div>
  );
};

const EstimatedDuration: React.FC<{ value: number; collapsed: boolean }> = ({
  value,
  collapsed,
}) => {
  return (
    <div className={'flex flex-row items-center my-1.5'}>
      <ClockIcon className='w-4 h-4 mr-1.5 flex-shrink-0' />
      <div className='font-semibold'>{convertMinToHours(value, collapsed)}</div>
    </div>
  );
};

export const TaskCard: React.FC<TaskTemplateCardType> = memo(
  ({
    taskTemplate,
    displayTaskName,
    pendingCard = false,
    editMode = false,
    collapsed = false,
    isDragging = false,
    isUpdating = false,
    formControl,
    className,
    onDelete,
    onEditModeChange,
  }) => {
    const {
      t,
      i18n: { resolvedLanguage },
    } = useTranslation();

    return (
      <div
        className={cn(
          'bg-background dark:bg-gray-800 my-2',
          'first:mt-0 border-2 border-slate-300 last:mb-0 active:cursor-grabbing',
          editMode && 'pb-0',
          isDragging && 'border-2 border-dashed border-slate-100 bg-slate-400',
          (isUpdating || pendingCard) && 'opacity-50 select-none pointer-events-none',
          className
        )}
      >
        <div
          className={cn(
            collapsed ? 'flex flex-col' : 'flex flex-row',
            isDragging && 'invisible opacity-50 bg-slate-500'
          )}
        >
          <div
            className={cn(
              'transition-colors duration-200 bg-defaultTeamColor shrink-0',
              collapsed ? 'h-2.5 min-w-full' : 'w-2.5 min-h-full'
            )}
            style={{
              backgroundColor: taskTemplate.teamColor ? `#${taskTemplate.teamColor}` : undefined,
            }}
          />
          <div className='flex-1 pl-1.5 pr-1 pt-0.5 pb-1'>
            {!collapsed && (
              <div className='flex flex-row justify-between'>
                {formControl ? (
                  <Controller
                    name='name'
                    control={formControl}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Editable
                        value={value}
                        onChange={onChange}
                        customDisplayComponent={
                          <div className='flex flex-row items-start group'>
                            <TaskName taskTemplate={taskTemplate} />
                            <Pencil1Icon className='w-4 h-4 ml-2 mt-1.5 opacity-0 group-hover:opacity-100 transition-opacity' />
                          </div>
                        }
                        onEditingChange={onEditModeChange}
                        changeOnBlur
                      >
                        {(props, ref) => (
                          <Input
                            {...props}
                            ref={ref as React.Ref<HTMLInputElement>}
                            className='flex-1 mt-1'
                          />
                        )}
                      </Editable>
                    )}
                  />
                ) : (
                  <TaskName taskTemplate={taskTemplate} displayTaskName={displayTaskName} />
                )}
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div className='p-1 h-fit cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-900 transition-all'>
                      <DotsVerticalIcon className='w-3.5 h-3.5' />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem className='text-destructive' onClick={onDelete}>
                      <TrashIcon className='w-5 mr-2' /> {t('delete')}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}

            {formControl ? (
              <Controller
                key={taskTemplate.id + '-' + taskTemplate.estimatedDuration} // Force re-render when estimatedDuration changes
                name='estimatedDuration'
                control={formControl}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Editable
                    value={Intl.NumberFormat(resolvedLanguage || fallbackLocale).format(value / 60)}
                    type='input'
                    onChange={(updatedValue) =>
                      onChange(parseLocaleNumber(updatedValue.toString()) * 60)
                    }
                    customDisplayComponent={
                      <div className='flex flex-row items-center group'>
                        <EstimatedDuration
                          value={taskTemplate.estimatedDuration}
                          collapsed={collapsed}
                        />
                        {!collapsed && (
                          <Pencil1Icon className='w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity' />
                        )}
                      </div>
                    }
                    onEditingChange={onEditModeChange}
                    changeOnBlur
                  >
                    {(props, ref) => (
                      <div className='flex flex-row items-center my-1.5 mr-1.5'>
                        {!collapsed && <ClockIcon className='w-4 h-4 mr-1.5 flex-shrink-0' />}
                        <NumericalInput
                          {...props}
                          ref={ref as React.Ref<HTMLInputElement>}
                          className='flex-1'
                        />
                        {!collapsed && <div className='ml-1.5'>{t('datetime.hours')}</div>}
                      </div>
                    )}
                  </Editable>
                )}
              />
            ) : (
              <EstimatedDuration value={taskTemplate.estimatedDuration} collapsed={collapsed} />
            )}

            {!collapsed && (
              <div className='py-0.5'>
                {formControl ? (
                  <Controller
                    name='teamId'
                    control={formControl}
                    render={({ field: { onChange, value } }) => (
                      <TeamSelector selectedTeamId={value} onChange={onChange} />
                    )}
                  />
                ) : (
                  <TeamSelector selectedTeamId={taskTemplate.teamId} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
